<template>
  <div>
    <!-- <a-spin :spinning="isFormLoading"> -->
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      layout="vertical"
      :scroll-to-first-error="true"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
    >
      <div class="row">
        <div class="col-lg-5">
          <a-form-item label="Rate Type" name="rateType">
            <a-radio-group v-model:value="formState.rateType" :disabled="isEdit">
              <a-radio-button value="customer">Customer</a-radio-button>
              <a-radio-button value="standard">Standard</a-radio-button>
            </a-radio-group>
          </a-form-item>
        </div>
        <div class="col-lg-5">
          <a-form-item label="Document Type" name="documentType">
            <a-radio-group v-model:value="formState.documentType" :disabled="isEdit">
              <a-radio-button value="dox">DOX</a-radio-button>
              <a-radio-button value="ndox">NDOX</a-radio-button>
            </a-radio-group>
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <a-form-item label="Customer" name="customerId">
            <a-select
              placeholder="Please select customer"
              v-model:value="formState.customerId"
              :show-search="true"
              option-filter-prop="label"
              :options="customersOptions"
              :disabled="formState.rateType === 'standard' || isEdit"
            />
          </a-form-item>
        </div>
        <div class="col-lg-5">
          <a-form-item label="Vendor" name="vendorId">
            <a-select
              placeholder="Please select vendor"
              v-model:value="formState.vendorId"
              :show-search="true"
              option-filter-prop="label"
              :options="vendorsOptions"
              :disabled="isEdit"
            />
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <a-form-item label="Zone" name="zoneId">
            <a-select
              placeholder="Please select zone"
              v-model:value="formState.zoneId"
              option-filter-prop="label"
              :options="filteredServiceToZoneOptions"
              :disabled="isEdit"
            />
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10">
          <a-form-item
            label=""
            name="rateCard"
            :rules="[
              {
                validator: validateRateCard,
              },
            ]"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10">
          <div v-for="(rate, idx) in formState.rateCard" :key="idx">
            <div class="row">
              <div class="col-lg-3">
                <a-form-item
                  label="From (kg)(not inc.)"
                  :name="['rateCard', idx, 'minw']"
                  :rules="[
                    {
                      validator: validateRateCardValues,
                      trigger: 'blur',
                    },
                  ]"
                >
                  <a-input-number :style="{ width: '100%' }" v-model:value="rate.minw" :min="0" />
                </a-form-item>
              </div>
              <div class="col-lg-3">
                <a-form-item
                  label="Upto (inc.)"
                  :name="['rateCard', idx, 'maxw']"
                  :rules="[
                    {
                      validator: validateRateCardValues,
                      trigger: 'blur',
                    },
                    {
                      validator: validateMaxWeight,
                      trigger: 'blur',
                    },
                  ]"
                >
                  <a-input-number :style="{ width: '100%' }" v-model:value="rate.maxw" :min="0" />
                </a-form-item>
              </div>
              <div class="col-lg-6">
                <a-form-item label="Cost (&#8377;)" :name="['rateCard', idx, 'cost']">
                  <div class="d-flex flex-wrap">
                    <a-input-number :style="{ width: '40%' }" v-model:value="rate.cost" :min="0" />
                    <div
                      class="d-flex flex-column justify-content-center ml-3"
                      v-if="idx === formState.rateCard.length - 1"
                    >
                      <a-tooltip title="Add">
                        <button
                          type="button"
                          class="btn btn-outline-secondary"
                          @click="handleAddCostRow"
                        >
                          <PlusOutlined />
                        </button>
                      </a-tooltip>
                    </div>
                    <div
                      class="d-flex flex-column justify-content-center ml-3"
                      v-if="formState.rateCard.length > 1"
                    >
                      <a-tooltip title="Remove">
                        <button
                          type="button"
                          class="btn btn-outline-secondary"
                          @click="handleRemoveCostRow(rate)"
                        >
                          <MinusOutlined />
                        </button>
                      </a-tooltip>
                    </div>
                  </div>
                </a-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-10">
          <div class="row">
            <div class="col-lg-4">
              <a-form-item label="Additional Weight (kg)" name="additionalWeight">
                <a-input-number
                  :style="{ width: '100%' }"
                  :min="0"
                  v-model:value="formState.additionalRate.weight"
                />
              </a-form-item>
            </div>
            <div class="col-lg-3">
              <a-form-item label="Cost (&#8377;)" name="addCost">
                <a-input-number
                  :style="{ width: '100%' }"
                  :min="0"
                  v-model:value="formState.additionalRate.cost"
                />
              </a-form-item>
            </div>
          </div>
        </div>
      </div>
      <div>
        <a-button html-type="submit" class="btn btn-primary px-5 mr-3" :loading="loading">
          Save
        </a-button>
      </div>
    </a-form>
    <!-- </a-spin> -->
  </div>
</template>
<script lang="ts">
import { RateFormData } from '@/types/appcontracts/RateFormData'
import { computed, defineComponent, PropType, reactive, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../../store/actions.type'
import { IVendorSearchMapping } from '@/types/interfaces/IVendorSearchMapping'
import { RuleObject } from 'ant-design-vue/lib/form/interface'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons-vue'
import { ICustomersSearchMapping } from '@/types/interfaces/ICustomersSearchMapping'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'
import { RateTypes } from '@/types/enums/RateTypes'
import { ShippingDocumentTypes } from '@/types/enums/ShippingDocumentTypes'
import { IShippingRateCard } from '@/types/interfaces/IShippingRateCard'

const getInitialState = (editData: RateFormData | null = null) => {
  const initialState: RateFormData = {
    rateType: editData?.rateType || RateTypes.Customer,
    documentType: editData?.documentType || ShippingDocumentTypes.Dox,
    vendorId: editData?.vendorId || null,
    customerId: editData?.customerId || null,
    zoneId: editData?.zoneId || null,
    rateCard: editData?.rateCard || [{ minw: 0, maxw: 0, cost: 0 }],
    additionalRate: editData?.additionalRate || { weight: 0, cost: 0 },
  }

  return initialState
}

export default defineComponent({
  name: 'RatesForm',
  components: {
    PlusOutlined,
    MinusOutlined,
  },
  props: {
    editData: {
      type: Object as PropType<RateFormData | null | undefined>,
      default: () => null,
    },
  },
  setup(props) {
    const store = useStore()
    const isEdit = computed(() => props.editData !== null && props.editData !== undefined)
    // const isFormLoading = ref<boolean>(false)
    const formRef = ref()
    const { zonesSearchList, serviceList } = useSearchMapping()
    const formState = reactive<RateFormData>(getInitialState(props.editData || null))
    const loading = ref(false)

    const serviceToZoneOptions = computed<Array<any>>(() => {
      return serviceList.value.map((vs: any) => {
        return {
          label: vs.name,
          vendorId: vs.vendorId,
          options: [
            ...zonesSearchList.value
              .filter((z) => z.serviceId === vs.id)
              .map((el) => {
                return {
                  label: el.name,
                  value: el.id,
                  key: el.id,
                }
              }),
          ],
        }
      })
    })

    const filteredServiceToZoneOptions = computed(() => {
      return serviceToZoneOptions.value.filter((x) => x.vendorId === formState.vendorId) || []
    })

    const vendorsOptions = computed(() =>
      store.state.vendors.vendorsSearchList.map((v: IVendorSearchMapping) => {
        return {
          value: v.id,
          label: v.displayName,
        }
      }),
    )
    const customersOptions = computed(() => {
      return store.state.customers.customersSearchList.map((cust: ICustomersSearchMapping) => {
        return {
          value: cust.id,
          label: `${cust.displayName} (${cust.cid})`,
          key: cust.id,
        }
      })
    })

    const validateRateCard = async () => {
      // Sort array in asc order.
      let arr = [...(formState.rateCard as Array<IShippingRateCard>)].sort(
        (a, b) => a.minw - b.minw,
      )

      for (let i = 0; i < arr.length; i++) {
        // Check if maxw > minw or not.
        if (arr[i].minw >= arr[i].maxw && arr[i].maxw != 0) {
          return Promise.reject('Invalid Range. From should be less then Upto.')
        }
        // Check for overlapping ranges.
        if (i > 0) {
          const prev = arr[i - 1]
          if (arr[i].minw < prev.maxw) {
            return Promise.reject('Overlapping Ranges.')
          }
        }
      }
      return Promise.resolve()
    }

    const validateRateCardValues = async (rule: RuleObject, value: number) => {
      if (value > 0) {
        formRef.value.validateField('rateCard')
      }
    }

    const validateMaxWeight = async (rule: RuleObject, value: number) => {
      if (value <= 0) {
        return Promise.reject('Weight should be greater then 0.')
      }
      return Promise.resolve()
    }

    const rules = computed(() => {
      return {
        rateType: [{ required: true, message: 'Please select rate type', trigger: 'blur' }],
        customerId: [
          {
            required: formState.rateType === 'customer',
            message: 'Please select a Customer',
            trigger: 'change',
            type: 'string',
          },
        ],
        vendorId: [
          { required: true, message: 'Please select a Vendor', trigger: 'change', type: 'string' },
        ],
        zoneId: [
          { required: true, message: 'Please select a Zone', trigger: 'blur', type: 'string' },
        ],
        documentType: [{ required: true, message: 'Please select document type', trigger: 'blur' }],
        rateCard: [
          { validator: validateRateCard, trigger: 'blur', type: 'array', required: 'true', len: 2 },
        ],
      }
    })

    const handleFinish = async () => {
      loading.value = true
      const docOperation: DocumentOperation<RateFormData> = {
        action: isEdit.value ? DocumentActions.UPDATE : DocumentActions.CREATE,
        id: isEdit.value ? formState.customerId || formState.vendorId : null,
        payload: toRaw(formState),
        audit: null,
      }
      const isSuccess = await store.dispatch(
        `rates/${actions.RatesAction.SET_OR_DELETE_SHIPPING_RATE}`,
        docOperation,
      )
      loading.value = false
      if (isSuccess) {
        Object.assign(formState, getInitialState())
      }
    }

    const handleAddCostRow = async () => {
      formState.rateCard?.push({ minw: 0, maxw: 0, cost: 0 })
    }

    const handleRemoveCostRow = async (rowObj: any) => {
      formState.rateCard?.splice(formState.rateCard?.indexOf(rowObj), 1)
    }
    // TODO: Remove if not needed.
    const handleFinishFailed = async (errors: any) => {
      console.log('ERR: ', errors)
    }

    // const fetchRateById = async () => {
    //   isFormLoading.value = true
    //   const id = props.editData
    //   if (id) {
    //     const resp = await store.dispatch(`rates/${actions.RatesAction.FETCH_RATES_BY_ID}`, {
    //       docId: id,
    //     })
    //   }
    //   isFormLoading.value = false
    // }

    // onMounted(async () => {
    //   await fetchRateById()
    // })

    // Not sure why 1st param is a func
    watch(
      () => formState.rateType,
      () => {
        formState.customerId = null
      },
    )

    return {
      formRef,
      formState,
      rules,
      loading,
      handleFinish,
      handleFinishFailed,
      handleAddCostRow,
      handleRemoveCostRow,
      customersOptions,
      vendorsOptions,
      serviceToZoneOptions,
      filteredServiceToZoneOptions,
      validateRateCard,
      validateRateCardValues,
      validateMaxWeight,
      isEdit,
    }
  },
})
</script>

<style lang="less" scoped></style>
